export const BLUE = "#2F55AB";
export const BLUE_DARK = "#29468A";
export const YELLOW = "#FFD530";

export const RED = "#FF6830";
export const PURPLE = "#8625A9";
export const PURPLE_DARK = "#431255";
export const GREEN_LIGHT = "#22B466";
export const GREEN_DARK = "#1B6F42";

export const GREY_DARK = "#2A2A2A";
export const GREY_LIGHT = "#3A3A3A";
export const GREY_SILVER = "#858585";

export const theme = {
  color: {
    pirmary: {
      main: BLUE,
      dark: BLUE_DARK
    },
    secondary: {
      main: YELLOW
    },
    accent: {
      RED,
      PURPLE,
      PURPLE_DARK,
      GREEN_LIGHT,
      GREEN_DARK
    },
    grey: {
      GREY_DARK,
      GREY_LIGHT,
      GREY_SILVER
    },
    white: {
      main: "#fff"
    }
  },
  typography: {
    fontFamily: {
      FredokaOne: "Fredoka One",
      Rubik: "Rubik"
    }
  }
};
