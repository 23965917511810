import React from "react";
import Text from "../elements/text";
import MainLayout from "../layouts/main";
import Headline from "../elements/headline";

const PrivacyScreen = () => (
  <MainLayout>
    <Headline>Datenschutzerklärung • Drinkflix®</Headline>
    <Text>
      Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders
      hohen Stellenwert für die Geschäftsleitung der Snash UG. Eine Nutzung der Drinkflix® App ist
      grundsätzlich ohne jede Angabe personenbezogener Daten möglich Sofern eine betroffene Person
      besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte,
      könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die
      Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung
      keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
    </Text>
    <Text>
      Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift,
      E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der
      Datenschutz-Grundverordnung und in Übereinstimmung mit den für die Snash UG geltenden
      landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser
      Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und
      verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels
      dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
    </Text>
    <Text>
      Die Snash UG hat als für die Verarbeitung Verantwortlicher zahlreiche technische und
      organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese
      Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können
      Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein
      absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen
      Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch,
      an uns zu übermitteln.
    </Text>
    <Headline>1. Begriffsbestimmungen</Headline>
    <Text>
      Die Datenschutzerklärung der Snash UG beruht auf den Begrifflichkeiten, die durch den
      Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
      (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als
      auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu
      gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.
    </Text>
    <Text>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</Text>
    <Text>a) personenbezogene Daten</Text>
    <Text>
      Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
      identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als
      identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere
      mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
      einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
      physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
      sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
    </Text>
    <Text>b) betroffene Person</Text>
    <Text>
      Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
      personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
    </Text>
    <Text>c) Verarbeitung</Text>
    <Text>
      Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
      jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das
      Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
      Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
      eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das
      Löschen oder die Vernichtung.
    </Text>
    <Text>d) Einschränkung der Verarbeitung</Text>
    <Text>
      Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit
      dem Ziel, ihre künftige Verarbeitung einzuschränken.
    </Text>
    <Text>e) Profiling</Text>
    <Text>
      Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin
      besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche
      Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte
      bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben,
      Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
      Person zu analysieren oder vorherzusagen.
    </Text>
    <Text>f) Pseudonymisierung</Text>
    <Text>
      Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
      personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer
      spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen
      Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen
      unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten
      oder identifizierbaren natürlichen Person zugewiesen werden.
    </Text>
    <Text>g) Verantwortlicher oder für die Verarbeitung Verantwortlicher</Text>
    <Text>
      Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
      juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
      anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
      Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der
      Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten
      Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen
      werden.
    </Text>
    <Text>h) Auftragsverarbeiter</Text>
    <Text>
      Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
      andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
    </Text>
    <Text>i) Empfänger</Text>
    <Text>
      Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
      Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um
      einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
      Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise
      personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
    </Text>
    <Text>j) Dritter</Text>
    <Text>
      Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle
      außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen,
      die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters
      befugt sind, die personenbezogenen Daten zu verarbeiten.
    </Text>
    <Text>k) Einwilligung</Text>
    <Text>
      Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
      informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung
      oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
      verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten
      einverstanden ist.
    </Text>
    <Headline>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</Headline>
    <Text>
      Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten
      der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit
      datenschutzrechtlichem Charakter ist die:
    </Text>
    <Text>
      Snash UG
      <br />
      Otto-Hahn-Str. 7<br />
      68623 Lampertheim
      <br />
      Deutschland
      <br />
      Tel.: 04486 9990314
      <br />
      E-Mail: hello[at]snash.eu
      <br />
      Website: snash.eu
    </Text>
    <Headline>3. Erfassung von allgemeinen Daten und Informationen</Headline>
    <Text>AA</Text>
    <Text>AA</Text>
    <Text>AA</Text>
    <Text>AA</Text>
    <Text>AA</Text>
  </MainLayout>
);

PrivacyScreen.propTypes = {};
PrivacyScreen.defaultProps = {};

export default PrivacyScreen;
