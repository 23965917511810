import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import Confetti from "react-dom-confetti";
import MainLayout from "../layouts/main";
import { theme } from "../identity/theme";
import back from "../../assets/back.png";
import logo from "../../assets/logo.png";
import Footer from "../elements/footer";

const config = {
  angle: "90",
  spread: 100,
  startVelocity: "40",
  elementCount: "183",
  dragFriction: 0.08,
  duration: "3000",
  delay: "0",
  width: "10px",
  height: "10px",
  colors: [
    theme.color.pirmary.main,
    theme.color.secondary.main,
    theme.color.accent.PURPLE,
    theme.color.accent.RED,
    theme.color.accent.GREEN_LIGHT,
  ],
};

const Root = styled.div`
  background-color: ${theme.color.grey.GREY_LIGHT};
  background-image: url(${back});
  background-repeat: repeat;
  height: 100%;
  background-size: contain;
`;
const CTA = styled.a`
  padding: 5px 15px;
  border: 3px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  border-radius: 60px;
  font-family: ${theme.typography.fontFamily.FredokaOne};
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
`;

const Text = styled.div`
  color: #fff;
  font-family: ${theme.typography.fontFamily.FredokaOne};
  align-items: center;
  line-height: 40px;
  padding: 10px 0;
`;
const LogoContainer = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
  :hover {
    animation: pulse 2s infinite;
    animation-timing-function: linear;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  :active {
    transform: scale(10);
  }
`;

const CFlex = styled(Flex)`
  height: 100%;
`;

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ready: false };
    setTimeout(() => this.setState({ ready: true }), 800);
  }
  action() {
    this.setState({ ready: true });
    setTimeout(() => {
      window.open("http://onelink.to/c6nc2n", "_blank");
      this.setState({ ready: false });
    }, 3000);
  }
  render() {
    return (
      <Root>
        <MainLayout>
          <CFlex justifyContent='center' flexDirection='column' alignItems='center'>
            <Confetti active={this.state.ready} config={config} />
            <LogoContainer src={logo} onClick={() => this.action()} />
            <Flex pt='15px' flexDirection='column' alignItems='center'>
              <CTA
                onClick={() => this.action()}
                href='http://onelink.to/c6nc2n'
                rel='noopener noreferrer'
                target='_blank'
              >
                App downloaden
              </CTA>
              <Text>oder</Text>
              <Box pt='15px'>
                <CTA
                  onClick={() => this.action()}
                  href='https://drinkflix.de/products/drinkflix-kartenspiel'
                >
                  Kartenspiel Shop
                </CTA>
              </Box>
            </Flex>
          </CFlex>
        </MainLayout>
        <Footer />
      </Root>
    );
  }
}

HomeScreen.propTypes = {};

HomeScreen.defaultProps = {};

export default HomeScreen;
