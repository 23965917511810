import React from "react";
import Text from "../elements/text";
import MainLayout from "../layouts/main";
import Headline from "../elements/headline";

const PrivacyAppScreen = () => (
  <MainLayout>
    <Headline>Datenschutzerklärung Drinkflix® App </Headline>
    <Text>
      Anbieter und Verantwortlicher
      <br />
      <br />
      Snash UG (haftungsbeschränkt)
      <br />
      Otto-Hahn-Str. 7<br />
      68623 Lampertheim
      <br />
      Deutschland
      <br />
      E-Mail: hello@snash.eu
      <br />
    </Text>
    <Headline>Begriffsbestimmungen</Headline>
    <Text>
      Die Datenschutzerklärung der Snash UG beruht auf den Begrifflichkeiten, die durch den
      Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
      (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als
      auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu
      gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.
    </Text>
    <Text>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</Text>
    <Text>a) personenbezogene Daten</Text>
    <Text>
      Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
      identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als
      identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere
      mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
      einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
      physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
      sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
    </Text>
    <Text>b) betroffene Person</Text>
    <Text>
      Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
      personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
    </Text>
    <Text>c) Verarbeitung</Text>
    <Text>
      Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
      jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das
      Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
      Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
      eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das
      Löschen oder die Vernichtung.
    </Text>
    <Text>d) Einschränkung der Verarbeitung</Text>
    <Text>
      Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit
      dem Ziel, ihre künftige Verarbeitung einzuschränken.
    </Text>
    <Text>e) Profiling</Text>
    <Text>
      Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin
      besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche
      Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte
      bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben,
      Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
      Person zu analysieren oder vorherzusagen.
    </Text>
    <Text>f) Pseudonymisierung</Text>
    <Text>
      Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
      personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer
      spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen
      Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen
      unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten
      oder identifizierbaren natürlichen Person zugewiesen werden.
    </Text>
    <Text>g) Verantwortlicher oder für die Verarbeitung Verantwortlicher</Text>
    <Text>
      Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
      juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
      anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
      Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der
      Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten
      Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen
      werden.
    </Text>
    <Text>h) Auftragsverarbeiter</Text>
    <Text>
      Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
      andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
    </Text>
    <Text>i) Empfänger</Text>
    <Text>
      Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
      Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um
      einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
      Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise
      personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
    </Text>
    <Text>j) Dritter</Text>
    <Text>
      Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle
      außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen,
      die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters
      befugt sind, die personenbezogenen Daten zu verarbeiten.
    </Text>
    <Text>k) Einwilligung</Text>
    <Text>
      Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
      informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung
      oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
      verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten
      einverstanden ist.
    </Text>
    <Headline>Arten der erhobenen Daten</Headline>
    <Text>
      Zu den personenbezogenen Daten, die diese Anwendung selbstständig oder durch Dritte
      verarbeitet, gehören: Eindeutige Gerätekennzeichnung für Werbung (Google-Werbe-ID oder IDFA,
      beispielsweise); Geografische Position. Vollständige Details zu jeder Art von verarbeiteten
      personenbezogenen Daten werden in den dafür vorgesehenen Abschnitten dieser
      Datenschutzerklärung oder punktuell durch Erklärungstexte bereitgestellt, die vor der
      Datenerhebung angezeigt werden. Personenbezogene Daten können vom Nutzer freiwillig angegeben
      oder, im Falle von Nutzungsdaten, automatisch erhoben werden, wenn diese Anwendung genutzt
      wird. Sofern nicht anders angegeben, ist die Angabe aller durch diese Anwendung angeforderten
      Daten obligatorisch. Weigert sich der Nutzer, die Daten anzugeben, kann dies dazu führen, dass
      diese Anwendung dem Nutzer ihre Dienste nicht zur Verfügung stellen kann. In Fällen, in denen
      diese Anwendung die Angabe personenbezogener Daten ausdrücklich als freiwillig bezeichnet,
      dürfen sich die Nutzer dafür entscheiden, diese Daten ohne jegliche Folgen für die
      Verfügbarkeit oder die Funktionsfähigkeit des Dienstes nicht anzugeben. Nutzer, die sich
      darüber im Unklaren sind, welche personenbezogenen Daten obligatorisch sind, können sich an
      den Anbieter wenden. Jegliche Verwendung von Cookies – oder anderer Tracking-Tools – durch
      diese Anwendung oder Anbieter von Drittdiensten, die durch diese Anwendung eingesetzt werden,
      dient dem Zweck, den vom Nutzer gewünschten Dienst zu erbringen, und allen anderen Zwecken,
      die im vorliegenden Dokument und, falls vorhanden, in der Cookie-Richtlinie beschrieben sind.
      Die Nutzer sind für alle personenbezogenen Daten Dritter verantwortlich, die durch diese
      Anwendung beschafft, veröffentlicht oder weitergegeben werden, und bestätigen, dass sie die
      Zustimmung zur Übermittlung personenbezogener Daten etwaiger Dritter an diese Anwendung
      eingeholt haben.
    </Text>
    <Headline>Art und Ort der Datenverarbeitung</Headline>
    <Text>Verarbeitungsmethoden</Text>
    <Text>
      Der Anbieter verarbeitet die Nutzerdaten auf ordnungsgemäße Weise und ergreift angemessene
      Sicherheitsmaßnahmen, um den unbefugten Zugriff und die unbefugte Weiterleitung, Veränderung
      oder Vernichtung von Daten zu vermeiden. Die Datenverarbeitung wird mittels Computern oder
      IT-basierten Systemen nach organisatorischen Verfahren und Verfahrensweisen durchgeführt, die
      gezielt auf die angegebenen Zwecke abstellen. Zusätzlich zum Verantwortlichen könnten auch
      andere Personen intern (Personalverwaltung, Vertrieb, Marketing, Rechtsabteilung,
      Systemadministratoren) oder extern – und in dem Fall soweit erforderlich, vom Verantwortlichen
      als Auftrags Verarbeiter benannt (wie Anbieter technischer Dienstleistungen,
      Zustellunternehmen, Hosting-Anbieter, IT-Unternehmen oder Kommunikationsagenturen) - diese
      Anwendung betreiben und damit Zugriff auf die Daten haben. Eine aktuelle Liste dieser
      Beteiligten kann jederzeit vom Anbieter verlangt werden.
    </Text>
    <Headline>Rechtsgrundlagen der Verarbeitung</Headline>
    <Text>
      Der Anbieter darf personenbezogene Daten von Nutzern nur dann verarbeiten, wenn einer der
      folgenden Punkte zutrifft: Die Nutzer haben ihre Einwilligung für einen oder mehrere bestimmte
      Zwecke erteilt. Hinweis: In einigen Gesetzgebungen kann es dem Anbieter gestattet sein,
      personenbezogene Daten zu verarbeiten, bis der Nutzer einer solchen Verarbeitung widerspricht
      ("Opt-out"), ohne sich auf die Einwilligung oder eine andere der folgenden Rechtsgrundlagen
      verlassen zu müssen. Dies gilt jedoch nicht, wenn die Verarbeitung personenbezogener Daten dem
      europäischen Datenschutzrecht unterliegt; die Datenerhebung ist für die Erfüllung eines
      Vertrages mit dem Nutzer und/oder für vorvertragliche Maßnahmen daraus erforderlich; die
      Verarbeitung ist für die Erfüllung einer rechtlichen Verpflichtung, der der Anbieter
      unterliegt, erforderlich; die Verarbeitung steht im Zusammenhang mit einer Aufgabe, die im
      öffentlichen Interesse oder in Ausübung hoheitlicher Befugnisse, die dem Anbieter übertragen
      wurden, durchgeführt wird; die Verarbeitung ist zur Wahrung der berechtigten Interessen des
      Anbieters oder eines Dritten erforderlich. In jedem Fall erteilt der Anbieter gerne Auskunft
      über die konkrete Rechtsgrundlage, auf der die Verarbeitung beruht, insbesondere darüber, ob
      die Angabe personenbezogener Daten eine gesetzliche oder vertragliche Verpflichtung oder eine
      Voraussetzung für den Abschluss eines Vertrages ist.
    </Text>
    <Headline>Ort</Headline>
    <Text>
      Die Daten werden in der Niederlassung des Anbieters und an allen anderen Orten, an denen sich
      die an der Datenverarbeitung beteiligten Stellen befinden, verarbeitet. Je nach Standort der
      Nutzer können Datenübertragungen die Übertragung der Daten des Nutzers in ein anderes Land als
      das eigene beinhalten. Um mehr über den Ort der Verarbeitung der übermittelten Daten zu
      erfahren, können die Nutzer den Abschnitt mit den ausführlichen Angaben zur Verarbeitung der
      personenbezogenen Daten konsultieren. Die Nutzer haben auch das Recht, sich über die
      Rechtsgrundlage der Datenübermittlung in ein Land außerhalb der Europäischen Union oder an
      eine internationale Organisation, die dem Völkerrecht unterliegt oder von zwei oder mehr
      Ländern gegründet wurde, wie beispielsweise die UNO, sowie sich über die vom Anbieter
      ergriffenen Sicherheitsmaßnahmen zum Schutz ihrer Daten aufklären zu lassen. Wenn eine solche
      Übermittlung stattfindet, kann der Nutzer mehr darüber erfahren, indem er die entsprechenden
      Abschnitte dieses Dokuments überprüft oder sich mit dem Anbieter über die im Kontaktteil
      angegebenen Informationen in Verbindung setzt.
    </Text>
    <Headline>Speicherdauer</Headline>
    <Text>
      Personenbezogene Daten werden so lange verarbeitet und gespeichert, wie es der Zweck
      erfordert, zu dem sie erhoben wurden. Daher gilt: Personenbezogene Daten, die zu Zwecken der
      Erfüllung eines zwischen dem Anbieter und dem Nutzer geschlossenen Vertrages erhoben werden,
      werden bis zur vollständigen Erfüllung dieses Vertrages gespeichert. Personenbezogene Daten,
      die zur Wahrung der berechtigten Interessen des Anbieters erhoben werden, werden so lange
      aufbewahrt, wie es zur Erfüllung dieser Zwecke erforderlich ist. Nutzer können nähere
      Informationen über die berechtigten Interessen des Anbieters in den entsprechenden Abschnitten
      dieses Dokuments oder durch Kontaktaufnahme zum Anbieter erhalten. Darüber hinaus ist es dem
      Anbieter gestattet, personenbezogene Daten für einen längeren Zeitraum zu speichern, wenn der
      Nutzer in eine solche Verarbeitung eingewilligt hat, solange die Einwilligung nicht widerrufen
      wird. Darüber hinaus kann der Anbieter verpflichtet sein, personenbezogene Daten für einen
      längeren Zeitraum aufzubewahren, wenn dies zur Erfüllung einer gesetzlichen Verpflichtung oder
      auf Anordnung einer Behörde erforderlich ist. Nach Ablauf der Aufbewahrungsfrist werden
      personenbezogene Daten gelöscht. Daher können das Auskunftsrecht, das Recht auf Löschung, das
      Recht auf Berichtigung und das Recht auf Datenübertragbarkeit nach Ablauf der
      Aufbewahrungsfrist nicht geltend gemacht werden.
    </Text>
    <Headline>Zwecke der Verarbeitung</Headline>
    <Text>
      Personenbezogene Daten über den Nutzer werden erhoben, damit der Anbieter die Dienstleistungen
      erbringen kann. Darüber hinaus werden Daten zu folgenden Zwecken erhoben: Überwachung der
      Infrastruktur, Verwalten von Kontakten und Versenden von Nachrichten und Testen der
      Performance von Inhalten und Funktionen (A/B-Test). Nutzer können in den entsprechenden
      Abschnitten dieses Dokuments weitere detaillierte Informationen zu diesen Verarbeitungszwecken
      und zu den für den jeweiligen Zweck verwendeten personenbezogenen Daten vorfinden.
    </Text>
    <Headline>Ausführliche Angaben über die Verarbeitung personenbezogener Daten</Headline>
    <Text>
      Personenbezogene Daten werden zu folgenden Zwecken unter Inanspruchnahme folgender
      Dienstleistungen erhoben:
    </Text>
    <Headline>
      Datenschutzbestimmungen zu Einsatz und Verwendung von Google Analytics (mit
      Anonymisierungsfunktion)
    </Headline>
    <Text>
      Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite die Komponente Google
      Analytics (mit Anonymisierungsfunktion) integriert. Google Analytics ist ein
      Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und Auswertung von Daten über das
      Verhalten von Besuchern von Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten
      darüber, von welcher Internetseite eine betroffene Person auf eine Internetseite gekommen ist
      (sogenannte Referrer), auf welche Unterseiten der Internetseite zugegriffen oder wie oft und
      für welche Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse wird überwiegend
      zur Optimierung einer Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung
      eingesetzt. Betreibergesellschaft der Google-Analytics-Komponente ist die Google Inc., 1600
      Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA. Der für die Verarbeitung Verantwortliche
      verwendet für die Web-Analyse über Google Analytics den Zusatz "_gat._anonymizeIp". Mittels
      dieses Zusatzes wird die IP-Adresse des Internetanschlusses der betroffenen Person von Google
      gekürzt und anonymisiert, wenn der Zugriff auf unsere Internetseiten aus einem Mitgliedstaat
      der Europäischen Union oder aus einem anderen Vertragsstaat des Abkommens über den
      Europäischen Wirtschaftsraum erfolgt. Der Zweck der Google-Analytics-Komponente ist die
      Analyse der Besucherströme auf unserer Internetseite. Google nutzt die gewonnenen Daten und
      Informationen unter anderem dazu, die Nutzung unserer Internetseite auszuwerten, um für uns
      Online-Reports, welche die Aktivitäten auf unseren Internetseiten aufzeigen,
      zusammenzustellen, und um weitere mit der Nutzung unserer Internetseite in Verbindung stehende
      Dienstleistungen zu erbringen. Google Analytics setzt ein Cookie auf dem
      informationstechnologischen System der betroffenen Person. Was Cookies sind, wurde oben
      bereits erläutert. Mit Setzung des Cookies wird Google eine Analyse der Benutzung unserer
      Internetseite ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die
      durch den für die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
      Google-Analytics-Komponente integriert wurde, wird der Internetbrowser auf dem
      informationstechnologischen System der betroffenen Person automatisch durch die jeweilige
      Google-Analytics-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an Google zu
      übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google Kenntnis über
      personenbezogene Daten, wie der IP-Adresse der betroffenen Person, die Google unter anderem
      dazu dienen, die Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge
      Provisionsabrechnungen zu ermöglichen. Mittels des Cookies werden personenbezogene
      Informationen, beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff ausging und
      die Häufigkeit der Besuche unserer Internetseite durch die betroffene Person, gespeichert. Bei
      jedem Besuch unserer Internetseiten werden diese personenbezogenen Daten, einschließlich der
      IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an Google in den
      Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten werden durch Google
      in den Vereinigten Staaten von Amerika gespeichert. Google gibt diese über das technische
      Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte weiter. Die betroffene
      Person kann die Setzung von Cookies durch unsere Internetseite, wie oben bereits dargestellt,
      jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern
      und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung des
      genutzten Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
      informationstechnologischen System der betroffenen Person setzt. Zudem kann ein von Google
      Analytics bereits gesetzter Cookie jederzeit über den Internetbrowser oder andere
      Softwareprogramme gelöscht werden. Ferner besteht für die betroffene Person die Möglichkeit,
      einer Erfassung der durch Google Analytics erzeugten, auf eine Nutzung dieser Internetseite
      bezogenen Daten sowie der Verarbeitung dieser Daten durch Google zu widersprechen und eine
      solche zu verhindern. Hierzu muss die betroffene Person ein Browser-Add-On unter dem Link
      https://tools.google.com/dlpage/gaoptout herunterladen und installieren. Dieses Browser-Add-On
      teilt Google Analytics über JavaScript mit, dass keine Daten und Informationen zu den Besuchen
      von Internetseiten an Google Analytics übermittelt werden dürfen. Die Installation des
      Browser-Add-Ons wird von Google als Widerspruch gewertet. Wird das informationstechnologische
      System der betroffenen Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu
      installiert, muss durch die betroffene Person eine erneute Installation des Browser-Add-Ons
      erfolgen, um Google Analytics zu deaktivieren. Sofern das Browser-Add-On durch die betroffene
      Person oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist, deinstalliert oder
      deaktiviert wird, besteht die Möglichkeit der Neuinstallation oder der erneuten Aktivierung
      des Browser-Add-Ons. Weitere Informationen und die geltenden Datenschutzbestimmungen von
      Google können unter https://www.google.de/intl/de/policies/privacy/ und unter
      http://www.google.com/analytics/terms/de.html abgerufen werden. Google Analytics wird unter
      diesem Link https://www.google.com/intl/de_de/analytics/ genauer erläutert.
    </Text>
    <Headline>
      Datenschutzbestimmungen zu Einsatz und Verwendung von Firebase und der Google Cloud Plattform
    </Headline>
    <Text>
      Unsere Apps nutzen Technologie von Google Firebase (Google Inc., 1600 Amphitheatre Parkway,
      Mountain View, CA 94043, USA, „Google“). Firebase ist Teil der Google Cloud Platform und
      bietet für Entwickler zahlreiche Dienste an. Eine Auflistung dazu finden Sie hier:
      https://firebase.google.com/terms/. Einige Firebase Dienste verarbeiten personenbezogene
      Daten. In den meisten Fällen beschränken sich die personenbezogene Daten auf sogenannte
      "Instance IDs", die mit einem Zeitstempel versehen sind. Diese von Firebase vergebenen
      "Instance IDs" sind einzigartig und erlauben damit das Verknüpfen von unterschiedlichen
      Geschehnissen oder Abläufen. Diese Daten stellen für uns weder persönlich identifizierbaren
      Informationen dar noch unternehmen wir Anstrengungen diese nachträglich zu personalisieren.
      Wir verarbeiten diese zusammengefassten Daten zur Analyse und Optimierung des
      Nutzungsverhaltens, wie beispielsweise durch die Auswertung von Absturzberichten. Für Firebase
      Analytics nutzt Google neben der oben beschriebenen "Instance ID" auch die Werbe-ID des
      Endgerätes. In den Geräteeinstellungen Ihres Mobilgerätes können Sie die Nutzung der Werbe-ID
      beschränken. Für Android: Einstellungen > Google > Anzeigen > Werbe-ID zurücksetzen Für iOS:
      Einstellungen > Datenschutz > Werbung > Kein Ad-Tracking Firebase Cloud Messaging dient dazu,
      Push-Nachrichten oder sogenannte In-App-Messages (Nachrichten, die nur innerhalb der
      jeweiligen App angezeigt werden) übermitteln zu können. Dabei wird dem Endgerät eine
      pseudonymisierte Push-Reference zugeteilt, die den Push-Nachrichten bzw. In-App-Messages als
      Ziel dient. Die Push-Nachrichten können in den Einstellungen des Endgeräts jederzeit
      deaktiviert und auch wieder aktiviert werden. Wir verwenden keine Firebase Dienste bei denen
      persönlich identifizierbare Informationen, wie beispielsweise IP-Adressen, E-Mail-Adressen,
      Telefonnummern oder Passwörter verwendet werden. Weitere Informationen zum Datenschutz und zur
      Sicherheit von Firebase finden Sie hier: https://firebase.google.com/support/privacy/ Wir
      nutzen nach Möglichkeit Server mit einem Standort innerhalb der EU. Es ist aber nicht
      auszuschließen, dass Daten auch in die USA übertragen werden. Google ist dem EU-US
      Privacy-Shield beigetreten, einem Datenschutzabkommen zwischen der EU und den USA. Nähere
      Informationen zu Google Firebase und Datenschutz finden sich unter
      https://www.google.com/policies/privacy/ sowie unter https://firebase.google.com/. Des
      Weiteren haben wir mit Google mehrere Verträge zur Auftragsdatenverarbeitung abgeschlossen.
      Darin verpflichtet sich Google dazu, die Daten unserer Nutzer zu schützen, entsprechend deren
      Datenschutzbestimmungen in unserem Auftrag zu verarbeiten und insbesondere nicht an Dritte
      weiter zu geben. Sie können dem Setzen bzw. der Verarbeitung von Cookies und dem damit
      einhergehenden Profiling über unsere Website widersprechen, wie in Punkt 5. der
      Datenschutzerklärung ausführlich beschrieben.
    </Text>
    <Headline>Testen der Performance von Inhalten und Funktionen (A/B-Test)</Headline>
    <Text>
      Mit den in diesem Abschnitt enthaltenen Diensten kann der Anbieter die Reaktion von Nutzern
      auf den Datenverkehr der Website oder ihr Verhalten, nachdem diese Anwendung in Bezug auf
      Struktur, Text oder sonstigen Komponenten verändert wurde, nachverfolgen und analysieren.
    </Text>
    <Headline>FIREBASE REMOTE CONFIG</Headline>
    <Text>
      Firebase Remote Config ist ein von Google LLC oder von Google Ireland Limited, je nach
      Standort, von dem aus auf diese Anwendung zugegriffen wird bereitgestellter Service zur
      Durchführung von A/B-Tests und Konfigurationen. Erhobene personenbezogene Daten: verschiedene
      Datenarten, wie in der Datenschutzerklärung des Dienstes beschrieben. Verarbeitungsort:
      Vereinigte Staaten – Datenschutzerklärung; Irland – Datenschutzerklärung. Privacy
      Shield-Mitglied.
    </Text>
    <Headline>Verwalten von Kontakten und Versenden von Nachrichten</Headline>
    <Text>
      Diese Art von Diensten ermöglichen die Verwaltung einer Datenbank mit E-Mail-Kontakten,
      Rufnummern oder jeglichen weiteren Kontaktinformationen, um mit dem Nutzer zu kommunizieren.
      Die Dienste können auch Daten darüber sammeln, an welchem Datum und zu welcher Uhrzeit
      Nachricht vom Nutzer gelesen wurde, sowie darüber, wann der Nutzer mit eingehenden Nachrichten
      interagiert, beispielsweise durch Klicken auf Links, die darin enthalten sind.
    </Text>
    <Headline>FIREBASE NOTIFICATIONS</Headline>
    <Text>
      Firebase Notifications ist ein Dienst für den Nachrichtenversand von Google LLC oder von
      Google Ireland Limited, je nach Standort, von dem aus auf diese Anwendung zugegriffen wird.
      Firebase Notifications können in Firebase Analytics integriert werden, um auf Analyse
      basierende Zielgruppen zu kreieren und um Konversion- und Zustellungsstatistiken zugreifen zu
      können. Erhobene personenbezogene Daten: verschiedene Datenarten, wie in der
      Datenschutzerklärung des Dienstes beschrieben. Verarbeitungsort: Vereinigte Staaten –
      Datenschutzerklärung; Irland – Datenschutzerklärung. Privacy Shield-Mitglied.
    </Text>
    <Headline>FIREBASE CLOUD MESSAGING</Headline>
    <Text>
      Firebase Cloud Messaging ist ein Dienst für den Nachrichtenversand, der von Google Inc.
      bereitgestellt wird. Firebase Cloud Messaging ermöglicht dem Eigentümer, über Plattformen wie
      Android, iOS und das Web Nachrichten und Benachrichtigungen an Nutzer zu senden. Nachrichten
      können an einzelne Geräte, Gerätegruppen, zu bestimmten Themen oder an bestimmte
      Benutzersegmente gesendet werden. Erhobene personenbezogene Daten: verschiedene Datenarten,
      wie in der Datenschutzerklärung des Dienstes beschrieben. Verarbeitungsort: Vereinigte Staaten
      – Datenschutzerklärung; Irland – Datenschutzerklärung. Privacy Shield-Mitglied.
    </Text>
    <Headline>Überwachung der Infrastruktur</Headline>
    <Text>
      Mit dieser Art von Diensten kann diese Anwendung zur Verbesserung der Leistung, des Betriebs,
      der Wartung und der Fehlersuche die Nutzung und das Verhalten ihrer einzelnen Bestandteile
      überwachen. Welche personenbezogenen Daten verarbeitet werden, hängt von den Eigenschaften und
      der Art der Ausführung der Dienste ab, deren Funktion das Filtern der über diese Anwendung
      stattfindenden Aktivitäten ist.
    </Text>
    <Headline>FIREBASE CRASH REPORTING (GOOGLE INC.)</Headline>
    <Text>
      Firebase Crash Reporting ist ein von Google, Inc. bereitgestellter Dienst für die Überwachung
      von Applikationen. Erhobene personenbezogene Daten: verschiedene Datenarten, wie in der
      Datenschutzerklärung des Dienstes beschrieben. Verarbeitungsort: Vereinigte Staaten –
      Datenschutzerklärung. Privacy Shield-Mitglied.
    </Text>
    <Headline>FIREBASE PERFORMANCE MONITORING</Headline>
    <Text>
      Firebase Performance Monitoring ist ein von Google LLC oder von Google Ireland Limited, je
      nach Standort, von dem aus auf diese Anwendung zugegriffen wird bereitgestellter Dienst für
      die Überwachung von Applikationen. Erhobene personenbezogene Daten: verschiedene Datenarten,
      wie in der Datenschutzerklärung des Dienstes beschrieben. Verarbeitungsort: Vereinigte Staaten
      – Datenschutzerklärung; Irland – Datenschutzerklärung. Privacy Shield-Mitglied.
    </Text>
    <Headline>CRASHLYTICS</Headline>
    <Text>
      Crashlytics ist ein von Google LLC oder von Google Ireland Limited, je nach Standort, von dem
      aus auf diese Anwendung zugegriffen wird, bereitgestellter Dienst für die Überwachung von
      Applikationen. Erhobene personenbezogene Daten: Eindeutige Gerätekennzeichnung für Werbung
      (Google-Werbe-ID oder IDFA, beispielsweise); Geografische Position; verschiedene Datenarten,
      wie in der Datenschutzerklärung des Dienstes beschrieben. Verarbeitungsort: Vereinigte Staaten
      – Datenschutzerklärung; Irland – Datenschutzerklärung.
    </Text>
    <Headline>Die Rechte der Nutzer</Headline>
    <Text>
      Die Nutzer können bestimmte Rechte in Bezug auf ihre vom Anbieter verarbeiteten Daten ausüben.
      Nutzer haben insbesondere das Recht, Folgendes zu tun: Die Einwilligungen jederzeit
      widerrufen. Hat der Nutzer zuvor in die Verarbeitung personenbezogener Daten eingewilligt, so
      kann er die eigene Einwilligung jederzeit widerrufen. Widerspruch gegen die Verarbeitung ihrer
      Daten einlegen. Der Nutzer hat das Recht, der Verarbeitung seiner Daten zu widersprechen, wenn
      die Verarbeitung auf einer anderen Rechtsgrundlage als der Einwilligung erfolgt. Weitere
      Informationen hierzu sind weiter unten aufgeführt. Auskunft bezüglich ihrer Daten erhalten.
      Der Nutzer hat das Recht zu erfahren, ob die Daten vom Anbieter verarbeitet werden, über
      einzelne Aspekte der Verarbeitung Auskunft zu erhalten und eine Kopie der Daten zu erhalten.
      Überprüfen und berichtigen lassen. Der Nutzer hat das Recht, die Richtigkeit seiner Daten zu
      überprüfen und deren Aktualisierung oder Berichtigung zu verlangen. Einschränkung der
      Verarbeitung ihrer Daten verlangen. Die Nutzer haben das Recht, unter bestimmten Umständen die
      Verarbeitung ihrer Daten einzuschränken. In diesem Fall wird der Anbieter die Daten zu keinem
      anderen Zweck als der Speicherung verarbeiten. Löschung oder anderweitiges Entfernen der
      personenbezogenen Daten verlangen. Die Nutzer haben unter bestimmten Umständen das Recht, die
      Löschung ihrer Daten vom Anbieter zu verlangen. Ihre Daten erhalten und an einen anderen
      Verantwortlichen übertragen lassen. Der Nutzer hat das Recht, seine Daten in einem
      strukturierten, gängigen und maschinenlesbaren Format zu erhalten und, sofern technisch
      möglich, ungehindert an einen anderen Verantwortlichen übermitteln zu lassen. Diese Bestimmung
      ist anwendbar, sofern die Daten automatisiert verarbeitet werden und die Verarbeitung auf der
      Zustimmung des Nutzers, auf einem Vertrag, an dem der Nutzer beteiligt ist, oder auf
      vorvertraglichen Verpflichtungen beruht. Beschwerde einreichen. Die Nutzer haben das Recht,
      eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
    </Text>
    <Headline>Details zum Widerspruchsrecht bezüglich der Verarbeitung</Headline>
    <Text>
      Werden personenbezogene Daten im öffentlichen Interesse, in Ausübung eines dem Anbieter
      übertragenen hoheitlichen Befugnisses oder zur Wahrung der berechtigten Interessen des
      Anbieters verarbeitet, kann der Nutzer dieser Verarbeitung widersprechen, indem er einen
      Rechtfertigungsgrund angibt, der sich auf seine besondere Situation bezieht. Nutzer werden
      darüber informiert, dass sie der Verarbeitung der personenbezogenen Daten für Direktwerbung
      jederzeit ohne Angabe von Gründen widersprechen können. Ob der Anbieter personenbezogene Daten
      für Direktwerbungszwecke verarbeitet, können die Nutzer den entsprechenden Abschnitten dieses
      Dokuments entnehmen.
    </Text>
    <Headline>Wie die Rechte ausgeübt werden können</Headline>
    <Text>
      Alle Anfragen zur Ausübung der Nutzerrechte können über die in diesem Dokument angegebenen
      Kontaktdaten an den Anbieter gerichtet werden. Anträge können kostenlos ausgeübt werden und
      werden vom Anbieter so früh wie möglich, spätestens innerhalb eines Monats, bearbeitet.
    </Text>
    <Headline>Rechtliche Maßnahmen</Headline>
    <Text>
      Die personenbezogenen Daten des Nutzers können vom Anbieter zu Zwecken der Rechtsdurchsetzung
      innerhalb oder in Vorbereitung gerichtlicher Verfahren verarbeitet werden, die sich daraus
      ergeben, dass diese Anwendung oder die dazugehörigen Dienste nicht ordnungsgemäß genutzt
      wurden. Der Nutzer erklärt, sich dessen bewusst zu sein, dass der Anbieter von den Behörden
      zur Herausgabe von personenbezogenen Daten verpflichtet werden könnte. Weitere Informationen
      über die personenbezogenen Daten des Nutzers Zusätzlich zu den in dieser Datenschutzerklärung
      aufgeführten Informationen kann diese Anwendung dem Nutzer auf Anfrage weitere kontextbezogene
      Informationen zur Verfügung stellen, die sich auf bestimmte Dienste oder auf die Erhebung und
      Verarbeitung personenbezogener Daten beziehen.
    </Text>
    <Headline>Systemprotokolle und Wartung</Headline>
    <Text>
      Diese Anwendung und die Dienste von Drittanbietern können zu Betriebs- und Wartungszwecken
      Dateien sammeln, die die über diese Anwendung stattfindende Interaktion aufzeichnen
      (Systemprotokolle), oder andere personenbezogene Daten (z. B. IP-Adresse) zu diesem Zweck
      verwenden.
    </Text>
    <Headline>Nicht in dieser Datenschutzerklärung enthaltene Informationen</Headline>
    <Text>
      Weitere Informationen über die Erhebung oder Verarbeitung personenbezogener Daten können
      jederzeit vom Anbieter über die aufgeführten Kontaktangaben angefordert werden.
    </Text>
    <Headline>Wie „Do Not Track“ Anfragen behandelt werden</Headline>
    <Text>
      Diese Anwendung unterstützt keine Nicht-Verfolgen-Anfragen („Do Not Track”) durch Webbrowser.
      Die Information, ob integrierte Drittdienste das Nicht-Verfolgen Protokoll unterstützen,
      entnehmen Nutzer der Datenschutzerklärung des jeweiligen Dienstes.
    </Text>
    <Headline>Änderungen dieser Datenschutzerklärung</Headline>
    <Text>
      Der Anbieter behält sich vor, jederzeit Änderungen an dieser Datenschutzerklärung vorzunehmen,
      indem sie ihre Nutzer auf dieser Seite und gegebenenfalls über diese Anwendung und/oder -
      soweit technisch und rechtlich möglich – durch das Versenden einer Mitteilung an die Nutzer
      über eine der dem Anbieter zur Verfügung stehenden Kontaktdaten entsprechend informiert.
      Nutzern wird daher nahe gelegt, diese Seite regelmäßig aufzurufen und dabei das am Seitenende
      angegebene Datum der letzten Änderung zu prüfen. Soweit Änderungen eine auf der Einwilligung
      des Nutzers basierte Datennutzung betreffen, so wird der Anbieter - soweit erforderlich - eine
      neue Einwilligung einholen.
    </Text>
    <Headline>RECHTLICHER HINWEIS</Headline>
    <Text>
      Diese Datenschutzerklärung wurde auf der Grundlage von Bestimmungen verschiedener
      Gesetzgebungen verfasst, einschließlich Art. 13/14 der Verordnung (EU) 2016/679
      (Datenschutz-Grundverordnung). Diese Datenschutzerklärung bezieht sich ausschließlich auf
      diese Anwendung, sofern in diesem Dokument nicht anders angegeben.
    </Text>
  </MainLayout>
);

PrivacyAppScreen.propTypes = {};
PrivacyAppScreen.defaultProps = {};

export default PrivacyAppScreen;
