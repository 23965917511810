import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeScreen from "./presentational/pages/home";
import PrivacyScreen from "./presentational/pages/privacy";
import PrivacyAppScreen from "./presentational/pages/privacy-app";
import LegalScreen from "./presentational/pages/legal";
import FaqScreen from "./presentational/pages/faq";
import JoinScreen from "./presentational/pages/join";

class App extends Component {
  render() {
    return (
      <Router>
        <Route path="/" exact component={HomeScreen} />
        <Route path="/join/:id" component={JoinScreen} />
        <Route path="/privacy" component={PrivacyScreen} />
        <Route path="/privacy-app" component={PrivacyAppScreen} />
        <Route path="/legal" component={LegalScreen} />
        <Route path="/faq/:lang" component={FaqScreen} />
      </Router>
    );
  }
}

export default App;
