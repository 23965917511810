import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import back from "../../assets/back.png";
import { theme } from "../identity/theme";
import Text from "../elements/text";
import Footer from "../elements/footer";

const Root = styled.div`
  background-color: ${theme.color.grey.GREY_LIGHT};
  background-image: url(${back});
  background-repeat: repeat;
  height: 100%;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LinkWrapper = styled.div`
  padding: 10px;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.2);
`;

const Link = styled.a`
  text-align: center;
  padding: 0px 25px;
  border-radius: 40px;
  display: inline-block;
  color: ${theme.color.white.main};
  text-decoration: none;
  background: ${theme.color.secondary.main};
  :active {
    opacity: 0.5;
  }
`;

const LinkText = styled(Text)`
  font-size: 18px;
  color: ${theme.color.grey.GREY_LIGHT};
  font-family: ${theme.typography.fontFamily.FredokaOne};
`;

const Or = styled(Text)`
  padding-top: 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
`;

const JoinScreen = ({ match }) => {
  const URL = `drinkflix://join/${match.params.id}`;
  window.location.href = URL;
  return (
    <React.Fragment>
      <Root>
        <LinkWrapper>
          <Link href={URL}>
            <LinkText>Spiel beitreten</LinkText>
          </Link>
        </LinkWrapper>
        <Or>ODER</Or>
        <a
          href='http://onelink.to/c6nc2n'
          style={{ textDecoration: "none" }}
          rel='noopener noreferrer'
          target='_blank'
        >
          <LinkText style={{ color: theme.color.white.main }}>Drinkflix downloaden</LinkText>
        </a>
        <Or>
          Lade dir Drinkflix aus dem App Store <br /> und öffnet erneut diesen Einladungslink.
        </Or>
      </Root>
      <Footer />
    </React.Fragment>
  );
};

JoinScreen.propTypes = {
  match: PropTypes.instanceOf(Object),
};
JoinScreen.defaultProps = {
  match: { params: { id: null } },
};

export default JoinScreen;
