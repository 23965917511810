import React from "react";
import Text from "../elements/text";
import MainLayout from "../layouts/main";
import Headline from "../elements/headline";

const LegalScreen = () => (
  <MainLayout>
    <Headline>Impressum</Headline>
    <Text>
      Snash UG (haftungsbeschränkt)
      <br />
      Otto-Hahn-Str. 7, <br />
      68623 Lampertheim
      <br />
      Deutschland
      <br />
      <br />
      Registergericht: Hessen Amtsgericht Darmstadt
      <br />
      Registernummer: 95547
      <br />
      USt-IdNr. (kommt bald)
      <br />
      Mail: support[a]snash.eu
      <br />
      Vertretungsberechtige Geschäftsführer:
      <br />
      Maximilian Hamm und Korbinian Klein
      <br />
    </Text>
  </MainLayout>
);

LegalScreen.propTypes = {};
LegalScreen.defaultProps = {};

export default LegalScreen;
