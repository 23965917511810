import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../identity/theme";
import { Flex } from "@rebass/grid";
import MainLayout from "../layouts/main";

const Root = styled.div`
  background: #000;
  padding: 15px;
  color: ${theme.color.grey.GREY_SILVER};
  font-family: ${theme.typography.fontFamily.Rubik};
  font-size: 11px;
  a {
    color: #fff;
    text-decoration: none;
  }
`;

const CFlex = styled(Flex)`
  > * {
    margin-right: 15px;
  }
`;

const Footer = () => (
  <Root>
    <MainLayout>
      Drinkflix.app copyright © 2021 • Drinkflix™ ist ein Warenzeichen der Snash UG
      (haftungsbeschränkt).
      <CFlex pt='8px'>
        <Link to='/legal'>Impressum</Link>
        <Link to='/faq/de_DE'>FAQ</Link>
        <Link to='/privacy-app'>Datenschutz</Link>
        <a href='https://snash.eu/#' rel='noopener noreferrer' target='_blank'>
          Snash UG (haftungsbeschränkt)
        </a>
      </CFlex>
    </MainLayout>
  </Root>
);

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
