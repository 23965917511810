import styled from "styled-components";
import { theme } from "../identity/theme";

const Headline = styled.div`
  font-size: 18px;
  line-height: 1.4;
  font-weight: bold;
  color: ${theme.color.pirmary.main};
  margin: 25px 0;
  font-family: ${theme.typography.fontFamily.Rubik};
`;

export default Headline;
