import React from "react";
import PropTypes from "prop-types";
import Text from "../elements/text";
import MainLayout from "../layouts/main";
import Headline from "../elements/headline";

const FaqScreen = ({ match }) => (
  <MainLayout>
    {match.params.lang === "de_DE" ? (
      <React.Fragment>
        <Headline>FAQ / Hilfe</Headline>
        <Headline>
          Kann ich meine Käufe von einem Android Gerät zu einem iPhone übertragen oder andersherum?
        </Headline>
        <Text>
          Nein, leider können wir das nicht machen. Dafür musst du dich an Apple oder Google wenden.
        </Text>
        <Headline>
          Werden meine gekauften Pakete gelöscht, wenn ich Drinkflix von meinem Smartphone lösche?
        </Headline>
        <Text>
          Deine Käufe sind mit deinem iOS/Android Account verknüpft. Du kannst Drinkflix erneut
          herunterladen und unter den Optionen deine “Käufe wiederherstellen”. Achtung: Deine Coins
          werden in der Installation gesichert und gehen bei dem Löschen der App verloren.
        </Text>
        <Headline>Meine freigeschalteten Pakete werden nicht geladen</Headline>
        <Text>
          Falls du zuvor schon Pakete freigeschaltet hast kannst du in den Optionen deine “Käufe
          wiederherstellen”.
        </Text>
        <Headline>Mein Kauf ist fehlgeschlagen obwohl ich schon gezahlt habe</Headline>
        <Text>
          Lösche die App und installier sie neu. Das kann manchmal schon ausreichen. Sollte das
          nicht helfen, schreib und gerne unter hello@drinkflix.de
        </Text>
        <Headline>
          Ich habe eine Idee, einen Wunsch, einen Verbesserungsvorschlag oder eine Beschwerde! An
          wen kann ich mich wenden?
        </Headline>
        <Text>
          Du kannst uns gerne eine E-Mail an support@snash.eu schreiben. Wir werden auch zeitnah
          antworten. Versprochen!
        </Text>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Headline>FAQ / Help</Headline>
        <Headline>
          Will my purchased packages be deleted when I delete Drinkflix from my smartphone?
        </Headline>
        <Text>
          No, don't panic. Your purchases are linked to your iOS/Android account. You can Download
          Drinkflix again and "Restore your purchases" under the options.
        </Text>
        <Headline>My unlocked packages are not loaded</Headline>
        <Text>
          If you have already unlocked packages before, you can check your "Purchases" in the
          options. Restore".
        </Text>
        <Headline>My purchase failed although I have already paid</Headline>
        <Text>Try to delete the app and reload it from the store.</Text>
        <Headline>
          I have an idea, a wish, a suggestion for improvement or a complaint! To who can I turn to?
        </Headline>
        <Text>
          You are welcome to send us an e-mail to support@snash.eu . We will also send you Answer.
          Promise!
        </Text>
      </React.Fragment>
    )}
  </MainLayout>
);

FaqScreen.propTypes = {
  match: PropTypes.instanceOf(Object),
};
FaqScreen.defaultProps = {
  match: { params: { lang: "de_DE" } },
};

export default FaqScreen;
