import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 8px 15px;
  box-sizing: border-box;
  height: 100%;
`;

const MainLayout = ({ children }) => {
  return <Root>{children}</Root>;
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {};

export default MainLayout;
