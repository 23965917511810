import styled from "styled-components";
import { theme } from "../identity/theme";

const Text = styled.div`
  font-size: 16px;
  line-height: 1.4;
  margin: 15px 0;
  font-family: ${theme.typography.fontFamily.Rubik};
`;

export default Text;
